import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore";
import { getPerformance } from "firebase/performance";

const config = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: "upshoot-ifga.firebaseapp.com",
  storageBucket: "upshoot-ifga.appspot.com",
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

let app;
try {
  app = initializeApp(config);
  console.log(`🚀 Firebase service loaded (app: ${app.options.projectId})`);
} catch (err) {
  console.log(`❌ Firebase service could not be initialized : ${err}`);
}
export const firebaseApp = app;

export const firebasePerf = getPerformance(app);

export const firestoreDb = getFirestore(firebaseApp);
