import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateEmail,
  reauthenticateWithCredential,
  updatePassword,
  sendPasswordResetEmail,
  deleteUser,
  updateProfile,
} from "firebase/auth";

import { setDocument, updateDocument, getDocument } from "@/services/firestore.service";
import { useUserStore } from "@/stores/user";

import * as Sentry from "@sentry/vue";

const provider = new GoogleAuthProvider();

export const auth = getAuth();

/**
 * The function `getAuthState` retrieves the current user authentication state and updates the user
 * store if a refresh is requested.
 * @param [refresh=false] - The `refresh` parameter in the `getAuthState` function is a boolean flag
 * that indicates whether the user's authentication state should be refreshed or not. If `refresh` is
 * set to `true`, the function will update the user's authentication state and user information in the
 * user store.
 * @returns The function `getAuthState` returns the current user object from the `auth` module, or an
 * empty object if the user is not authenticated.
 */
export async function getAuthState(refresh = false, updateFromCurrUser = true) {
  const userStore = useUserStore();

  if (refresh) try {
    const currUser = auth.currentUser;

    userStore.modifyLoggedState(currUser != null);
    userStore.modifyUser(currUser || {});

    if (currUser) {
      console.log(`👤🔁 User reloaded`);

      if (updateFromCurrUser) updateUserProfile(currUser.displayName, currUser.photoURL || currUser?.providerData[0]?.photoURL);

      Sentry.setUser({
        id: currUser.uid,
        email: currUser.email,
        username: currUser.displayName,
      });
    } else Sentry.setUser(null);
  } catch (error) {
    console.error("👤❌ Error refreshing user store :", error);
  }

  return auth.currentUser || {};
}

/**
 * The function `accessWithGoogle` allows a user to sign in with Google authentication and handles the
 * login process.
 * @returns The `accessWithGoogle` function returns a Promise. If the sign-in with Google is
 * successful, it returns `true`. If there is an error during the sign-in process, it returns `false`.
 */
export function accessWithGoogle() {
  return signInWithPopup(auth, provider)
    .then((result) => {
      const user = result.user;

      updateDocument(`users/${user.uid}`, {
        displayName: user.displayName,
        photoURL: user.photoURL,
      });

      getAuthState(true);

      const output = {
        userLoggedIn: true,
        userObject: auth.currentUser,
        message: `👤✔️ User ${user.uid} logged in (provider: Google)`,
      };

      console.debug(output);

      return output;
    })
    .catch((error) => {
      const output = {
        userLoggedIn: false,
        userObject: auth.currentUser,
        message: "👤❌ Error logging with Google :" + error,
      };

      console.error(output);

      return output;
    });
}

/**
 * The function `accessWithEmailAndPassword` logs in a user with email and password authentication and
 * handles success and error cases accordingly.
 * @param email - The `email` parameter is the email address that the user.
 * @param password - The `password` parameter is the current password of the user.
 * @returns The `accessWithEmailAndPassword` function returns a Promise. If the sign-in with email and
 * password is successful, it returns `true`. If there is an error during the sign-in process, it
 * returns `false`.
 */
export function accessWithEmailAndPassword(email, password) {
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;

      updateDocument(`users/${user.uid}`, {
        displayName: user.displayName,
        photoURL: user.photoURL,
        lastLogin: new Date(),
      });

      getAuthState(true);

      const output = {
        userLoggedIn: true,
        userObject: auth.currentUser,
        message: `👤✔️ User ${user.uid} logged in (provider: Email)`,
      };

      console.debug(output);

      return output;
    })
    .catch((error) => {
      const output = {
        userLoggedIn: false,
        userObject: auth.currentUser,
        message: "👤❌ Error logging with email:password :" + error,
      };

      console.error(output);

      return output;
    });
}

/**
 * The function `signupWithEmailPassword` signs up a user with email and password authentication and
 * logs the result.
 * @param email - The chosen email for the account.
 * @param password - The chosen password for the account.
 * @returns The `signupWithEmailPassword` function returns a Promise that resolves to a boolean value.
 * If the user signup is successful, it returns `true`. If there is an error during the signup process,
 * it returns `false`.
 */
export function signupWithEmailPassword(email, password) {
  return createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;

      setDocument(`users/${user.uid}`, {
        displayName: user.displayName,
        photoURL: user.photoURL,
        creationDate: new Date(),
      });

      getAuthState(true);

      const output = {
        useSignedUp: true,
        userObject: auth.currentUser,
        message: `👤✔️ User signed up (provider: Email)`,
      };

      console.debug(output);

      return output;
    })
    .catch((error) => {
      const output = {
        useSignedUp: false,
        userObject: auth.currentUser,
        message: "👤❌ Unable to signup user :" + error,
      };

      console.error(output);

      return output;
    });
}

export async function logout() {
  if (!auth.currentUser) return {
    userLoggedOut: false,
    userObject: auth.currentUser,
    message: "👤❔ No user to logout",
  };

  return await signOut(auth)
    .then(() => {
      getAuthState(true);

      const output = {
        userLoggedOut: true,
        userObject: auth.currentUser,
        message: "👤✔️ User logged out",
      };

      console.debug(output);

      return output;
    })
    .catch((error) => {
      const output = {
        userLoggedOut: false,
        userObject: auth.currentUser,
        message: "👤❌ Unable to logout user :" + error,
      };

      console.error(output);

      return output;
    });
}

export async function changeUserEmail(newEmail, currentPassword) {
  if (!newEmail) {
    console.error("👤❌ newEmail is incorrect", newEmail);
    return false;
  }
  if (newEmail === auth.currentUser.email) {
    console.warn("👤 No need to update user email (same emails)");
    return true;
  }

  await refreshUserSession(currentPassword);

  return await updateEmail(auth.currentUser, newEmail)
    .then(() => {
      console.log(`👤✔️ User email updated successfully`);
      getAuthState(true);

      return true;
    })
    .catch((error) => {
      console.error("👤❌ Unable to update user email :", error);

      return false;
    });
}

export async function changePassword(currentPassword, newPassword) {
  if (!currentPassword || !newPassword) {
    console.error("👤❌ All password are not provided", currentPassword, newPassword);
    return false;
  }

  await refreshUserSession(currentPassword);

  return await updatePassword(auth.currentUser, newPassword)
    .then(() => {
      console.log(`👤✔️ User password updated successfully`);
      getAuthState(true);

      return true;
    })
    .catch((error) => {
      console.error("👤❌ Unable to update user password :", error);

      return false;
    });
}

export function refreshUserSession(password) {
  return signInWithEmailAndPassword(auth, auth.currentUser.email, password)
    .then((userCredential) => {
      console.log(
        `👤✔️ User signed up (provider: Email)`,
        userCredential.user.uid,
      );

      reauthenticateWithCredential(auth.currentUser, userCredential)
        .then(() => {
          console.log(`👤✔️ User session refreshed successfully`);
          getAuthState(true);

          return true;
        })
        .catch((error) => {
          console.error("👤❌ Unable to refresh session for user :", error);

          return false;
        });
    })
    .catch((error) => {
      console.error("👤❌ Error logging with email:password :", error);
      return false;
    });
}

export function askResetPassword() {
  return sendPasswordResetEmail(auth, auth.currentUser.email)
    .then(() => {
      console.log("👤✔️ Reset password email successfully sent");
      return true;
    })
    .catch((error) => {
      console.error("👤❌ Error sending reset password :", error);
      return false;
    });
}

export async function deleteUserAccount(currentPassword) {
  await refreshUserSession(currentPassword);

  return updateDocument(`users/${auth.currentUser.uid}`, {
    displayName: "Utilisateur inconnu",
    photoURL: "",
  }).then(() => {
    return deleteUser(auth.currentUser)
      .then(() => {
        console.log("👤✔️ User was successfully deleted");
        return true;
      });
  }).catch((error) => {
    console.error("👤❌ Error deleting user :", error);
    return false;
  });
}

export function updateUserProfile(displayName, photoURL) {
  return updateDocument(`users/${auth.currentUser.uid}`, {
    displayName,
    photoURL,
    lastUpdate: {
      timestamp: new Date(),
      origin: "currUser",
    },
  }).then(() => {
    return updateProfile(auth.currentUser, {
      displayName,
      photoURL,
    }).then(() => {
      console.log("👤✔️ User profile was successfully updated");
      getAuthState(true, false);
      return true;
    });
  }).catch((error) => {
    console.error("👤❌ Error updating user profile :", error);
    return false;
  });
}

export async function getUserInfo(userId) {
  const userData = await getDocument(`users/${userId}`);

  return {
    id: userData.id,
    displayName: userData.displayName,
    photoURL: userData.photoURL,
  };
}
