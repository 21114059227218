import { defineStore } from "pinia";

export const useUserStore = defineStore("userStore", {
  state: () => ({
    user: {},
    userVerbose: {},
    logged: false,
  }),
  actions: {
    modifyLoggedState(state) {
      this.logged = state;
    },
    modifyUser(user) {
      if (user.uid) this.user = {
        uid: user.uid,
        email: user.email,
        photoURL: user.providerData?.photoURL || user.providerData[0]?.photoURL,
        displayName: user.providerData?.displayName || user.providerData[0]?.displayName,
      };

      this.userVerbose = user;
    },
  },
});
