import { createApp } from "vue";
import { createPinia } from "pinia";
import { registerPlugins } from "@/plugins";

import App from "./App.vue";

import * as Sentry from "@sentry/vue";

import { firebaseApp } from "@/services/firebase.service";

import packageJson from "../package.json";

const app = createApp({
  ...App,
  provide: {
    firebaseApp,
  },
});

import { createHead } from "unhead";

createHead();

Sentry.init({
  app,
  dsn: "https://7184079ffec73b4b3588779361989656@o4505749003894784.ingest.us.sentry.io/4507147019419648",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: import.meta.env.VITE_SENTRY_SAMPLERATE || 0.1,
  tracePropagationTargets: [
    "localhost",
    "https://beta.app.upshoot.cc",
    "https://app.upshoot.cc",
  ],

  release: packageJson.version,
  environment: process.env.NODE_ENV,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_SAMPLERATE || 0.1,
});

app.use(createPinia());

registerPlugins(app);

app.mount("#app");
