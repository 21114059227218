/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "vuetify/styles";

// Composables
import { createVuetify } from "vuetify";

// Fonts
import "@mdi/font/css/materialdesignicons.css";

const mainTheme = {
  dark: false,
  colors: {
    background: "#303030",
    white: "#F6F6F6",
    grey: "#C1C1C1",
    black: "#303030",

    primary: "#CE9EF3",
    secondary: "#88C3CE",
    accent: "#64387D",

    error: "#EC5F5F",
    success: "#9DDE8C",
    warning: "#ECDE5F",
  },
};

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  icons: {
    defaultSet: "mdi",
  },
  defaultAssets: {
    font: {
      family: "Poppins",
    },
  },
  theme: {
    defaultTheme: "mainTheme",
    themes: {
      mainTheme,
    },
  },
  defaults: {
    VBtn: {
      rounded: "xl",
    },
  },
});
