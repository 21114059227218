<template>
  <v-app>
    <v-main>
      <v-slide-x-transition mode="out-in">
        <router-view />
      </v-slide-x-transition>
    </v-main>
  </v-app>
</template>

<script setup>
import { useHead } from "unhead";

useHead({
  title: "UpShoot App",
  meta: [
    {
      name: "title",
      content: "UpShoot App",
    },
    {
      name: "description",
      content: "🎥 Setup, Shoot, Repeat.",
    },

    {
      name: "og:type",
      content: "website",
    },
    {
      name: "og:url",
      content: "https://app.upshoot.cc",
    },
    {
      name: "og:title",
      content: "UpShoot App",
    },
    {
      name: "og:description",
      content: "🎥 Setup, Shoot, Repeat.",
    },

    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:url",
      content: "https://app.upshoot.cc",
    },
    {
      name: "twitter:title",
      content: "UpShoot App",
    },
    {
      name: "twitter:description",
      content: "🎥 Setup, Shoot, Repeat.",
    },
  ],
});
</script>

<script>
import { auth, getAuthState } from "@/services/auth.service";
import { onAuthStateChanged } from "firebase/auth";

export default {
  name: "App",
  data() {
    return {
      listenedOnce: false,
    };
  },
  beforeCreate() {
    onAuthStateChanged(auth, () => {
      this.checkAuthenticatedRoute(true);
    });
  },
  methods: {
    async checkAuthenticatedRoute(reload) {
      if (this.listenedOnce) {
        getAuthState(reload);

        if (this.$route.meta.requiresAuth && !auth.currentUser) this.$router.push("/access");
      } else this.listenedOnce = true;
    },
  },
  watch: {
    $route: {
      handler: function() {
        this.checkAuthenticatedRoute(false);
      },
      deep: true,
    },
  },
};
</script>
