import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: () => import("@/views/HomeView.vue"),
    },
    {
      path: "/access",
      component: () => import("@/views/Access.vue"),
    },
    {
      path: "/profile",
      component: () => import("@/views/Profile.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/projects",
      component: () => import("@/views/Projects.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/project/:projectId",
      component: () => import("@/views/Project.vue"),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/project/:projectId/create",
      component: () => import("@/views/project/ProjectCreate.vue"),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/project/join/:projectPincode",
      component: () => import("@/views/project/JoinProjectView.vue"),
      props: true,
    },

    {
      path: "/workflow/users",
      component: () => import("@/views/WorkflowSandboxView.vue"),
    },
    {
      path: "/workflow/projects",
      component: () => import("@/views/ProjectSandboxView.vue"),
    },
  ],
});

export default router;
