import {
  getFirestore,
  doc,
  setDoc,
  addDoc,
  collection,
  deleteDoc,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { firebaseApp } from "./firebase.service";

const db = getFirestore(firebaseApp);

/**
 * This function sets a document in a Firestore database at a specified path with error handling.
 * @param relativePath - Relative path is the path to the document in the Firestore database.
 * @param documentObject - Data object that you want to store.
 * @returns `true` if the document was successfully added, and `false` if there was an error adding the document.
 */
export async function updateDocument(relativePath, documentObject) {
  try {
    await updateDoc(doc(db, relativePath), documentObject);
    return true;
  } catch (e) {
    console.error("❌ Error updating document : ", e);
    return false;
  }
}

export async function setDocument(relativePath, documentObject) {
  try {
    await setDoc(doc(db, relativePath), documentObject);
    return true;
  } catch (e) {
    console.error("❌ Error setting document : ", e);
    return false;
  }
}

export async function addDocument(collectionPath, documentObject) {
  try {
    return await addDoc(collection(db, collectionPath), documentObject);
  } catch (e) {
    console.error("❌ Error adding document : ", e);
    return false;
  }
}

export async function deleteDocument(relativePath) {
  try {
    await deleteDoc(doc(db, relativePath));
    return true;
  } catch (e) {
    console.error("❌ Error removing document : ", e);
    return false;
  }
}

export async function getDocument(relativePath) {
  try {
    const resDoc = await getDoc(doc(db, relativePath));
    return {
      ...resDoc.data(),
      id: resDoc.id,
    };
  } catch (e) {
    console.error("❌ Error getting document : ", e);
    return false;
  }
}

export async function getDocuments(relativePath) {
  try {
    const querySnapshot = await getDocs(collection(db, relativePath));

    const documents = [];

    querySnapshot.forEach((doc) => {
      documents.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    const output = {
      size: documents.length,
      documents,
    };

    return output;
  } catch (error) {
    const output = {
      error,
      documents: [],
      message: "❌ Error getting documents",
    };

    console.error(output);

    return output;
  }
}

export async function queryDocument(collectionPath, queryFilters) {
  try {
    const queryRef = collection(db, collectionPath);

    const queryConstraints = [];
    queryFilters.forEach((filter) => {
      queryConstraints.push(where(filter[0], filter[1], filter[2]));
    });

    const documentsQuery = query(queryRef, ...queryConstraints);

    const docs = await getDocs(documentsQuery);
    const outputDocs = [];

    docs.forEach((doc) => {
      outputDocs.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return outputDocs;
  } catch (e) {
    console.error("❌ Error querying documents : ", e);
    return false;
  }
}
